// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  loading: false,
}

const hospitalStaff = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_HOSPITALSTAFF_WITH_PAG':


      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        loading: action.loaderStatus,
      }
    case 'SET_LOADER':
      return { ...state, loading: action.loaderStatus }
    case 'GET_HOSPITALSTAFF_BY_ID':
      return {
        ...state,
        speciality: action.selectedUser,
        loading: action.loaderStatus,
      }
    case 'ADD_HOSPITALSTAFF':
      return { ...state }
    case 'UPDATE_HOSPITALSTAFF':
      return { ...state }
    case 'DELETE_HOSPITALSTAFF':
      return { ...state }
    default:
      return { ...state }
  }
}
export default hospitalStaff
