// ** Initial State
import { useHistory } from 'react-router-dom'

const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  da: '',
  doctor: null,
  loading: false
}

const users = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DOCTOR_WITH_PAG':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        loading: action.loaderStatus
      }
    case 'SET_LOADER':
      return { ...state, loading: action.loaderStatus }
    case 'GET_DOCTOR_BY_ID':
      return { ...state, doctor: action.selectedUser, loading: action.loaderStatus }
    case 'ADD_DOCTOR':
      return { ...state }
    case 'UPDATE_DOCTOR':
      return { ...state }
    case 'UPDATE_STATUS':
      return { ...state }
    case 'DELETE_DOCTOR':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
