// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    patient: null,
    loading: false
}

const patients = (state = initialState, action) => {

    switch (action.type) {

        case 'GET_DATA_WITH_PAG':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                loading: action.loaderStatus
            }
        case 'SET_LOADER':
            return { ...state, loading: action.loaderStatus }
        case 'GET_PATIENT_BY_ID':
            return { ...state, patient: action.selectedUser, loading: action.loaderStatus }
        case 'ADD_PATIENT':
            return { ...state }
        case 'UPDATE_STATUS':
            return { ...state }
        case 'DELETE_PATIENT':
            return { ...state }
        default:
            return { ...state }
    }
}
export default patients
