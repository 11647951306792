// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

import patientStore from '@src/views/apps/patients/store/reducer/PatientReducer'
import doctorStore from '@src/views/apps/doctor/store/reducer/DoctorReducer'
import appointmentStore from '@src/views/apps/appointment/store/reducer/AppointmentReducer'
import hospitalStore from '@src/views/apps/hospital/store/reducer/HospitalReducer'
import pharmacyStore from '@src/views/apps/pharmacy/store/reducer/PharmacyReducer'
import courierStore from '@src/views/apps/courier/store/reducer/PharmacyReducer'
import courierOrderStore from '@src/views/apps/courier_orders/store/reducer/CourierOrderReducer'
import complaintStore from '@src/views/apps/complaints/store/reducer/ComplaintReducer'
import specialityStore from '@src/views/apps/speciality/store/reducer/SpecialityReducer'
import languageStore from '@src/views/apps/language/store/reducer/LanguageReducer'
import reportNameStore from '@src/views/apps/reportname/store/reducer/ReportNameReducer'
import hospitalStaffStore from '@src/views/apps/hospitalStaff/store/reducer/hospitalStaffReducer'
import healthtipsStore from '@src/views/apps/healthtips/store/reducer/HealthtipsReducer'
import labStore from '@src/views/apps/lab/store/reducer/LabReducer'
import labReportStore from '@src/views/apps/labreport/store/reducer/LabReportReducer'
import messageStore from '@src/views/apps/message/store/reducer/MessageReducer'
import feedbackStore from '@src/views/apps/feedback/store/reducer/FeedbackReducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  patientStore,
  doctorStore,
  appointmentStore,
  hospitalStore,
  pharmacyStore,
  courierStore,
  courierOrderStore,
  complaintStore,
  specialityStore,
  languageStore,
  hospitalStaffStore,
  healthtipsStore,
  labStore,
  labReportStore,
  reportNameStore,
  messageStore,
  feedbackStore
})

export default rootReducer
