// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  complaint: null,
  loading: false
}

const users = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_COMPLAINT_WITH_PAG':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        loading: action.loaderStatus
      }
    case 'SET_LOADER':
      return { ...state, loading: action.loaderStatus }
    case 'GET_COMPLAINT_BY_ID':
      return { ...state, complaint: action.selectedUser, loading: action.loaderStatus }
    case 'UPDATE_COMPLAINT':
      return { ...state }
    case 'DELETE_COMPLAINT':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
