// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedUser: null,
    courier_order: null,
    loading: false
  }
  
  const users = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_COURIER_ORDER_WITH_PAG':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
          loading: action.loaderStatus
        }
      case 'SET_LOADER':
        return { ...state, loading: action.loaderStatus }
      case 'GET_COURIER_ORDER_BY_ID':
        return { ...state, courier_order: action.selectedUser, loading: action.loaderStatus }
      case 'DELETE_COURIER_ORDER':
        return { ...state }
      default:
        return { ...state }
    }
  }
  export default users
  