// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  loading: false
}

const users = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_HEALTHTIPS_WITH_PAG':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        loading: action.loaderStatus
      }
    case 'SET_LOADER':
      return { ...state, loading: action.loaderStatus }
    case 'GET_HEALTHTIPS_BY_ID':
      return { ...state, healthtips: action.selectedUser, loading: action.loaderStatus }
    case 'ADD_HEALTHTIPS':
      return { ...state }
    case 'UPDATE_HEALTHTIPS':
      return { ...state }
    case 'DELETE_HEALTHTIPS':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
